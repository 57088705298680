import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import style from '../../Styles/projectHistory.module.css';
import { MdPadding } from 'react-icons/md';
import ShowStatus from '../ReusableComponents/ShowStatus';
import dayjs from 'dayjs';
import TmPagination from '../ReusableComponents/Pagination';
import { NORECORD, PaginationRange } from '../../constant/constant';
const ProjectHistoryTable = ({ rows, header }) => {
  const [pagination, setPagination] = useState([]);
  useEffect(() => {
    PaginationRange.setPageSize(10);
    handlePagination(1, rows);
  }, [rows]);

  const onShowSizeChange = (current, pageSize) => {
    PaginationRange.setPageSize(pageSize);
    handlePagination(current, rows);
  };
  const handlePagination = async (data, current = []) => {
    let endRange = data * Number(PaginationRange.endRange) - 1;
    let startRange =
      data * Number(PaginationRange.startRange) -
      Number(PaginationRange.initalStart);
    const check = current.length ? current : rows;
    setPagination(
      check.filter((_, index) => index <= endRange && index >= startRange),
    );
  };

  return (
    <div className="">
      {/* <div className={`pt-1  mt-2 ps-4 fw-bold ${style.PHFont}`}>
        {header} History
      </div> */}
      <div className={`mt-md-2 mt-2 ps-1  ${style.modelhistorytab} `}>
        <Table>
          <thead>
            <tr
              style={{
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
                zIndex: 2,
              }}
            >
              <th>FIELD TYPE</th>
              <th>{header.toUpperCase()} NAME</th>
              <th>DATE</th>
              <th>ORGINAL VALUE</th>
              <th>UPDATED VALUE</th>
              <th>UPDATED BY</th>
              <th>REASON</th>
              {/* <th>Log</th> */}
            </tr>
          </thead>
          <tbody>
            {pagination.length <= 0 && (
              <tr>
                <td colSpan={100} className="text-center">
                  {NORECORD}
                </td>
              </tr>
            )}
            {pagination.length > 0 &&
              pagination?.map((item, idx) => (
                <tr key={idx}>
                  <td>{<ShowStatus status={item.logType} />}</td>
                  <td>
                    {(item['Module.module_name']
                      ? item['Module.module_name']
                      : item['Project.project_name']) || '--'}
                  </td>
                  <td>{item.date || '--'}</td>
                  <td>
                    {dayjs(item.orginalValue).isValid() &&
                    ['START DATE', 'END DATE'].includes(item.logType)
                      ? dayjs(item.orginalValue).format('DD-MM-YYYY')
                      : item.orginalValue || '--'}
                  </td>
                  <td>
                    {dayjs(item.updatedValue).isValid() &&
                    ['START DATE', 'END DATE'].includes(item.logType)
                      ? dayjs(item.updatedValue).format('DD-MM-YYYY')
                      : item.updatedValue || '--'}
                  </td>
                  {/* <td>{item.updatedValue || '--'}</td> */}
                  <td>{item.updatedBy || '--'}</td>
                  <td style={{ maxWidth: 1000 }}>
                    {[
                      'CREATION',
                      'DEACTIVATED',
                      'ACTIVATED',
                      'DATE ENDED',
                    ].includes(item.logType)
                      ? item.logContent
                      : item.reason || '--'}
                    <span style={{ color: '#646871' }}>
                      {' '}
                      - {dayjs(
                        item.createdAt,
                        'DD-MM-YYYY h:mm A',
                      ).fromNow()}{' '}
                    </span>
                  </td>

                  {/* <td style={{ maxWidth: 1000 }}>
                    <span className="mb-0 py-0 px-2 rounded-pill">
                      {item?.logContent} -{' '}
                      <span style={{ color: '#646871' }}>
                        {dayjs(item.createdAt, 'DD-MM-YYYY h:mm A').fromNow()}{' '}
                      </span>
                    </span>
                  </td> */}
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <div className="mt-2 mt-md-0">
        <TmPagination
          onShowSizeChange={onShowSizeChange}
          total={rows.length}
          handlePagination={handlePagination}
        />
      </div>
    </div>
  );
};

export default ProjectHistoryTable;
