import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { FiEdit } from 'react-icons/fi';
import { MdDeleteOutline } from 'react-icons/md';
import style from '../../Styles/ProjectTrackingModel.module.css';
import { NORECORD, PaginationRange } from '../../constant/constant';

import { IoEyeOutline } from 'react-icons/io5';
import TmPagination from '../ReusableComponents/Pagination';
import ShowStatus from '../ReusableComponents/ShowStatus';

const inputHeader = ['action', 'checkbox'];

const ProjectTrackingTable = (props) => {
  const { rows, columns, toggleModal, custom, handleDelete, buttonPermission } =
    props;

  const [pagination, setPagination] = useState([]);
  let columnKey = [];
  let customKey = [...custom];
  if (rows.length > 0) {
    columnKey = columns.map((item) => item.field);
  }

  useEffect(() => {
    PaginationRange.setPageSize(10);
    handlePagination(1, rows);
  }, [rows]);

  const handlePagination = async (data, current = []) => {
    let endRange = data * Number(PaginationRange.endRange) - 1;
    let startRange =
      data * Number(PaginationRange.startRange) -
      Number(PaginationRange.initalStart);
    const check = current.length ? current : rows;
    setPagination(
      check.filter((_, index) => index <= endRange && index >= startRange),
    );
  };

  const onShowSizeChange = (current, pageSize) => {
    PaginationRange.setPageSize(pageSize);
    handlePagination(current, rows);
  };
  return (
    <div className="icon-btn">
      <Table className="table-header-custom border" responsive>
        <thead>
          <tr>
            <th
              style={{ minWidth: '180px' }}
              rowSpan={2}
              className={`${style.borderside}`}
            >
              USER NAME
            </th>
            <th
              style={{ minWidth: '180px' }}
              rowSpan={2}
              className={`${style.borderside}`}
            >
              EMPLOYEE CODE
            </th>
            <th className={`${style.borderBottom}`} colSpan={100}>
              PROJECTS
            </th>
          </tr>
          <tr>
            {columns.length > 0 &&
              columns.map((column, index) => (
                <>
                  {column.field != 'employeeCode' &&
                    column.field != 'username' && (
                      <th
                        className={`${
                          inputHeader.includes(column.field)
                            ? 'text-center'
                            : null
                        }`}
                        key={index + 1}
                      >
                        {column.headerName}
                        <span style={{ color: ' #7d8189' }}>
                          ({column.count} hrs)
                        </span>
                      </th>
                    )}
                </>
              ))}
          </tr>
        </thead>
        <tbody>
          {pagination.length <= 0 && (
            <tr>
              <td colSpan={100} className="text-center">
                {NORECORD}
              </td>
            </tr>
          )}
          {pagination.length > 0 &&
            pagination?.map((row, idx) => (
              <tr key={idx + 1} className="hoverTr">
                {columnKey.map((item, index) => (
                  <td
                    key={index + 1}
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleModal('View', row)}
                  >
                    {item !== 'status' ? (
                      <span title={row[item]}>{row[item] || '--'}</span>
                    ) : (
                      <ShowStatus status={row[item] ? 'Active' : 'InActive'} />
                    )}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </Table>
      <div>
        <TmPagination
          onShowSizeChange={onShowSizeChange}
          total={rows.length}
          handlePagination={handlePagination}
        />
      </div>
    </div>
  );
};

export default ProjectTrackingTable;
