import React, { useEffect, useState } from 'react';
import { Menu, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { defaultKeySetter, items } from './sideBarArray';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../Context/AuthContext';
import UserRole from '../../Api/UserRole';
import { toastError } from '../../Components/Toast/ToastComponent';
import {
  getCheckPermission,
  getDashboard,
  setSettingsData,
} from '../../store/reducers/actionPermission';
import Settings from '../../Api/settingsApi';
import {
  removeAuthInfo,
  setSessionSettings,
} from '../../Context/AuthSessionStorage';

let selectedKey = '/dashboard';

const SideBar = () => {
  const navigate = useNavigate();
  const data = useSelector(getDashboard);

  useEffect(() => {
    if (location.pathname === '/') {
      selectedKey = data?.checked ? '/dashboard' : '/timesheet';
    }
  }, [data?.checked]);

  const [stateOpenKeys, setStateOpenKeys] = useState([]);
  const [menu, setMenu] = useState([]);
  // const [defaultKey, setDefaultKey] = useState(['/timesheet']);
  const dispatch = useDispatch();
  const { authState, logout } = useAuth();
  const location = useLocation();

  useEffect(() => {
    getMenu();
    getSettings();
    if (location.pathname !== '/') {
      let splited = location.pathname.split('/');
      let key = splited[1].replace(/[^a-zA-Z ]/g, '').trim();
      let newArr = defaultKeySetter[key] || '/timesheet';
      selectedKey = newArr;
      // setDefaultKey([newArr]);
    }
  }, [authState, location.pathname]);

  const getSettings = async () => {
    try {
      let response = await Settings.get();
      if (response.data.success) {
        setSessionSettings(JSON.stringify(response.data.result[0]));
        dispatch(setSettingsData(response.data.result[0]));
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  const getLevelKeys = (items1) => {
    const key = {};
    const func = (items2, level = 1) => {
      items2.forEach((item) => {
        if (item.key) {
          key[item.key] = level;
        }
        if (item.children) {
          func(item.children, level + 1);
        }
      });
    };
    func(items1);
    return key;
  };

  const levelKeys = getLevelKeys(items);

  const onOpenChange = (openKeys) => {
    const currentOpenKey = openKeys.find(
      (key) => stateOpenKeys.indexOf(key) === -1,
    );
    // open
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
      setStateOpenKeys(
        openKeys
          // remove repeat key
          .filter((_, index) => index !== repeatIndex)
          // remove current level all child
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey]),
      );
    } else {
      // close
      setStateOpenKeys(openKeys);
    }
  };

  const getMenu = async () => {
    try {
      const response = await UserRole.getMenuPermission({
        id: authState?.userRoleID,
      });
      if (response.data.success) {
        const menuCheck = JSON.parse(response.data.result.user_permissions);
        const filteredArray = items.filter((item) => {
          const checkItem = menuCheck.find(
            (checkItem) => checkItem.statename === item.statename,
          );
          if (checkItem && checkItem.checked) {
            if (item.children) {
              item.children = item.children.filter((child) => {
                const childCheckItem = checkItem.children.find(
                  (checkChild) => checkChild.statename === child.statename,
                );
                return childCheckItem && childCheckItem.checked;
              });
            }
            return true;
          }
          return false;
        });
        dispatch(getCheckPermission(menuCheck));
        setMenu(filteredArray);
      } else {
        removeAuthInfo();
        logout();
        toastError(response.data.message);
      }
    } catch (error) {
      removeAuthInfo();
      logout();
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  const handleMenuClick = (item) => {
    navigate(item.key);
  };
  const handleLogoClick = (item) => {
    navigate(item.key);
  };

  return (
    <>
      {menu.length === 0 ? (
        <div className="text-center">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      ) : (
        <div>
          <Menu
            theme="dark"
            mode="inline"
            // defaultSelectedKeys={defaultKey}
            openKeys={stateOpenKeys}
            onClick={(item) => handleMenuClick(item)}
            onOpenChange={onOpenChange}
            style={{ fontSize: 16 }}
            items={menu}
            selectedKeys={[selectedKey]}
          />
        </div>
      )}
    </>
  );
};
export default SideBar;
