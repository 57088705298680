import axiosClient from './axiosClient';

export const ProjectApi = {
  get: (data) => {
    const url = `/api/project/get`;
    return axiosClient.post(url, data);
  },
  createProject: (data) => {
    const url = `/api/project/create`;
    return axiosClient.post(url, data);
  },
  update: (data) => {
    const url = `/api/project/edit`;
    return axiosClient.post(url, data);
  },
  dropDown: (data) => {
    const url = `api/project/dropDown`;
    return axiosClient.post(url, data);
  },
  getProjectByID: (id) => {
    const url = `/api/project/get/${id}`;
    return axiosClient.post(url);
  },
  delete: (data) => {
    const url = `/api/project/delete`;
    return axiosClient.post(url, data);
  },
  /*************  ✨ Codeium Command ⭐  *************/
  /**
   * Get the project tracking data
   * @param {object} data - Request data
   * @return {Promise} - Response promise
   */
  /******  98ab971b-7ef2-4779-8b42-dbf61fdf7c22  *******/
  getProjectTracking: (data) => {
    const url = `/api/report/user/projecthours`;
    return axiosClient.post(url, data);

  },
  getProjectHistoryTable: (data) => {
    const url = `/api/project/projectLogs`;
    return axiosClient.post(url, data);
  }
};
