import React, { useRef, useState } from 'react';
import UserLogTable from './UserLogTable';
import TmFilter from '../ReusableComponents/TmFilter';
import { Col, Input, Row } from 'antd';
import Loader from '../ReusableComponents/Loader';
import {
  generateCurrentMonth,
  generateCurrentYear,
  generateYears,
} from '../../ReusableFn/reusableFn';
import { DAY_TYPES, EVENT_TYPE, MONTHS } from '../../constant/constant';
import Department from '../../Api/Department';
import { toastError, toastInfo } from '../Toast/ToastComponent';
import { useEffect } from 'react';
import { useAuth } from '../../Context/AuthContext';
import {
  getDepartment,
  getUserLog,
} from '../../store/reducers/actionPermission';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useFilterStateData from '../../customHooks/useFilterStateData';
const { Search } = Input;

let columns = [
  // {
  //   feildname: 'username',
  //   className: 'col-2 text-nowrap',
  //   headerName: 'EMPLOYEE NAME',
  // },
  { field: 'Events', headerName: 'EVENT TYPE', className: 'col-1' },
  { field: 'department_name', headerName: 'DEPARTMENT', className: 'col-2' },
  { field: 'date', headerName: 'DATE', className: 'col-1' },
  { field: 'logs', headerName: 'LOG', className: 'col-5' },
];

const UserLogComponents = () => {
  const [loading, setLoading] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);
  const [userLogs, setUserLogs] = useState([]);
  const navigate = useNavigate();
  const debounceTimeoutRef = useRef(null);
  const { month: filterStateMonth, year: filterStateYear } =
    useFilterStateData();
  const [filterValue, setFilterValue] = useState({
    monthName: filterStateMonth,
    year: filterStateYear,
    eventType: '',
    department_name: null,
    search: '',
  });
  // let filterValue = {
  //   monthName: filterStateMonth,
  //   year: filterStateYear,
  //   eventType: '',
  //   department_name: null,
  //   search: '',
  // };
  let filterArray = [
    {
      options: MONTHS,
      optionFilterProp: 'label',
      placeholder: 'Month',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: filterStateMonth,
      controlName: 'monthName',
      allowClear: false,
      input: 'select',
    },
    {
      options: generateYears() || [],
      optionFilterProp: 'label',
      placeholder: 'Year',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: filterStateYear,
      controlName: 'year',
      allowClear: false,
      input: 'select',
    },

    {
      options: EVENT_TYPE,
      optionFilterProp: 'label',
      placeholder: 'Event Types',
      order: 1,
      col: 2,
      mode: '',
      maxTagCount: '',
      defaultValue: '',
      controlName: 'eventType',
      allowClear: true,
      input: 'select',
    },
    {
      options: departmentList,
      optionFilterProp: 'label',
      placeholder: 'Department',
      order: 1,
      col: 2,
      mode: '',
      maxTagCount: '',
      defaultValue: null,
      controlName: 'department_name',
      allowClear: true,
      input: 'select',
    },
  ];
  const data = useSelector(getUserLog);
  const { authState } = useAuth();

  const getUserLogs = async (obj) => {
    try {
      const reqData = {
        ...obj,
        id: authState?.id,
        userRoleID: authState?.userRoleID,
      };
      const response = await Department.viewActivity(reqData);
      if (response?.data?.success) {
        setUserLogs(response?.data?.result);
        setLoading(true);
      } else {
        toastInfo(response?.data?.message);
      }
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };
  const handleButtonPermission = () => {
    if (!data?.checked) {
      navigate('/');
    }
  };

  const getDepartmentList = async () => {
    try {
      const response = await Department.dropDown({});
      if (response?.data?.success) {
        setDepartmentList(response?.data?.result);
      } else {
        toastInfo(response?.data?.message);
      }
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };
  const handleSearch = (e) => {
    let value = e.target.value.trim();

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      handleFilterChange({ ...filterValue, search: value });
    }, 400);
  };
  const handleFilterChange = (obj, type) => {
    setFilterValue((pre) => ({ ...pre, ...obj }));
    getUserLogs({ ...filterValue, ...obj });
  };

  useEffect(() => {
    getDepartmentList();
    getUserLogs(filterValue);
    if (data) {
      handleButtonPermission();
    }
  }, [data]);

  return (
    <div>
      <div>
        <div className="row mx-0 mb-2 ">
          <div className="col-md-4 px-0">
            <h5 className="text-nowrap">User Logs</h5>
          </div>
          <div className="col-md-3 px-0 ps-md-5" style={{ marginLeft: 'auto' }}>
            <Search allowClear onChange={handleSearch} placeholder="Search " />
          </div>
        </div>

        <div className="mt-2 mb-0">
          {' '}
          <TmFilter
            filterArray={filterArray}
            handleFilterChange={handleFilterChange}
          />
        </div>
        {loading && (
          <div>
            <div className="">
              <UserLogTable columns={columns} rows={userLogs} />
            </div>
          </div>
        )}

        {!loading && <Loader />}
      </div>
    </div>
  );
};

export default UserLogComponents;
