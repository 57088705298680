import React, { useState } from 'react';
import { Drawer, Checkbox, Input } from 'antd';
import style from '../../Styles/ColumnFilter.module.css';
import { setSessionColumn } from '../../Context/AuthSessionStorage';
import { toastInfo } from '../Toast/ToastComponent';
import { MINIMUM_COLUMN_SELECT } from '../../constant/constant';
import { useDispatch } from 'react-redux';
import { setColumn } from '../../store/reducers/filterState';

const ColumnFilter = ({ visible, onClose, columnFilter, setColumnFilter }) => {
  const [searchValue, setSearchValue] = useState('');
  const dispatch = useDispatch();

  const handleCheckboxChange = (element, e) => {
    let isChecked =
      typeof element === 'number' ? e.target.checked : element.target.checked;

    let newFilters = columnFilter.map((item) => ({ ...item }));

    if (typeof element === 'number') {
      let minSelected = newFilters.filter((item) => item.isChecked).length;

      if (minSelected > MINIMUM_COLUMN_SELECT || isChecked) {
        newFilters[element] = {
          ...newFilters[element],
          isChecked,
        };
      } else {
        return toastInfo(
          `At least ${MINIMUM_COLUMN_SELECT} columns must be selected.`,
        );
      }
    } else {
      newFilters = newFilters.map((item, index) => ({
        ...item,
        isChecked: isChecked || index < MINIMUM_COLUMN_SELECT,
      }));
    }

    setColumnFilter(newFilters);
    dispatch(setColumn(newFilters));
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value.toLowerCase());
  };

  const filteredColumns = columnFilter.filter((item) =>
    item.headerName.toLowerCase().includes(searchValue),
  );

  const isAllSelected =
    filteredColumns.length > 0 &&
    filteredColumns.every((item) => item.isChecked);

  return (
    <div>
      <Drawer
        title="Column Filter"
        placement="right"
        onClose={onClose}
        visible={visible}
      >
        <section className="overflow">
          <div className="row mx-0">
            <div className="col-10 ">
              <div className={`mt-3 pt-2  ms-1 `}>
                <div className="ps-2">
                  <Input
                    placeholder="Search columns..."
                    onChange={handleSearch}
                    className="mb-3"
                    allowClear
                  />

                  {!searchValue.length && (
                    <Checkbox
                      checked={isAllSelected}
                      onChange={handleCheckboxChange}
                    >
                      <span>Select All</span>
                    </Checkbox>
                  )}
                </div>

                {filteredColumns.map((item, index) => (
                  <div
                    key={index}
                    className={`mt-3 ps-1 ms-1 ${style.forboredr}`}
                  >
                    <div className={`pt-2 ${style.BorderColor}`}>
                      <Checkbox
                        disabled={item?.isDisabled}
                        checked={item.isChecked}
                        onChange={(e) =>
                          handleCheckboxChange(columnFilter.indexOf(item), e)
                        }
                      >
                        <span className={` ${style.fontcolor}`}>
                          {item.headerName}
                        </span>
                      </Checkbox>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        {filteredColumns?.length <= 0 && (
          <section className="mt-3 d-flex justify-content-center">
            <div className="text-secondary fw-semibold">No data found</div>
          </section>
        )}
      </Drawer>
    </div>
  );
};

export default ColumnFilter;
