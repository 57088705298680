import React, { useEffect, useState } from 'react';
import style from '../../Styles/projectHistory.module.css';
import { FaArrowLeft, FaRegClock } from 'react-icons/fa6';
import ProjectHistoryCardComp from './projectHistoryCardComp';

import ProjectHistoryTable from './projectHistoryTable';
import ModelHistoryComp from './modelHistoryComp';
import { ProjectApi } from '../../Api/ProjectApi';
import { Tabs } from 'antd';
import { EVENT_TYPE, MONTHS, PROJECT_EVENTS } from '../../constant/constant';
import { generateYears } from '../../ReusableFn/reusableFn';
import useFilterStateData from '../../customHooks/useFilterStateData';
import TmFilter from '../ReusableComponents/TmFilter';
import { toastError } from '../Toast/ToastComponent';
import { useNavigate, useParams } from 'react-router-dom';

const ProjectHistoryComp = () => {
  const { month: filterStateMonth, year: filterStateYear } =
    useFilterStateData();
  const [response, setResponse] = useState({
    projectHistory: [],
    moduleHistory: [],
  });
  const [header, SetHeader] = useState(1);
  const { edit_id } = useParams();
  const navigate = useNavigate();

  const [filterValue, setFilterValue] = useState({
    monthName: filterStateMonth,
    year: filterStateYear,
    eventType: '',
  });

  let filterArray = [
    {
      options: MONTHS,
      optionFilterProp: 'label',
      placeholder: 'Month',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: filterStateMonth,
      controlName: 'monthName',
      allowClear: false,
      input: 'select',
    },
    {
      options: generateYears() || [],
      optionFilterProp: 'label',
      placeholder: 'Year',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: filterStateYear,
      controlName: 'year',
      allowClear: false,
      input: 'select',
    },

    {
      options: PROJECT_EVENTS,
      optionFilterProp: 'label',
      placeholder: 'Event Types',
      order: 1,
      col: 2,
      mode: '',
      maxTagCount: '',
      defaultValue: '',
      controlName: 'eventType',
      allowClear: true,
      input: 'select',
    },
  ];

  const projectHistoryTabApi = async (object) => {
    try {
      const response = await ProjectApi.getProjectHistoryTable({
        ...object,
        project_id: atob(edit_id),
      });
      if (response?.data?.success) {
        setResponse((prev) => ({
          ...prev,
          projectHistory: response?.data?.result?.projectHistory,
          moduleHistory: response?.data?.result?.moduleHistory,
        }));
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      toastError(error);
    }
  };

  const onChange = (key) => {
    SetHeader(key);
  };
  const items = [
    {
      key: '1',
      label: 'Project',
      children: (
        <div>
          <ProjectHistoryTable
            header={'Project'}
            rows={response?.projectHistory}
          />
        </div>
      ),
    },
    {
      key: '2',
      label: 'Module',
      children: (
        <div>
          <ProjectHistoryTable
            header={'Module'}
            rows={response?.moduleHistory}
          />
        </div>
      ),
    },
  ];
  const handleFilterChange = (obj, type) => {
    setFilterValue((pre) => ({ ...pre, ...obj }));
    projectHistoryTabApi({ ...filterValue, ...obj });
  };
  useEffect(() => {
    projectHistoryTabApi({ ...filterValue });
  }, []);

  return (
    <div>
      <div className="d-flex ">
        <div className="">
          <div
            className={`${style.arrow} border px-2  pb-1`}
            onClick={() => navigate(`/projects`)}
          >
            <FaArrowLeft />
          </div>
        </div>
        <div style={{ paddingTop: 1 }} className={`  ps-2 ${style.PHFont}`}>
          <h5>{header == 1 ? 'Project' : 'Module'} History</h5>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12  ">
          <div className="">
            <TmFilter
              filterArray={filterArray}
              handleFilterChange={handleFilterChange}
            />
          </div>
          <div className={` mt-2    ${style.backgroundcolor23}`}>
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectHistoryComp;
